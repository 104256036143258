import React from 'react';
import { bool, node, oneOf, string } from 'prop-types';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import {
  MdPinDrop,
  MdFavorite,
  MdSupervisorAccount,
  MdPerson,
} from 'react-icons/md';
import BaseDivider from '@bit/medicalwebexperts.mwe-ui.divider';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Icon from '@bit/medicalwebexperts.mwe-ui.icon';

const propTypes = {
  children: node.isRequired,
  divider: bool,
  icon: oneOf(['pin', 'favorite', 'account', 'person']).isRequired,
  subtitle: string,
  title: string.isRequired,
};

const defaultProps = {
  divider: true,
  subtitle: null,
};

const Subtitle = styled.small(
  css({
    color: 'gray.700',
    display: 'block',
    fontWeight: 'normal',
  }),
);

const Divider = styled(BaseDivider)(
  css({
    my: 12,
  }),
);

const ICON_MAPPING = {
  pin: MdPinDrop,
  favorite: MdFavorite,
  account: MdSupervisorAccount,
  person: MdPerson,
};

const Section = ({ children, divider, icon, subtitle, title }) => (
  <>
    <Flex alignItems={!subtitle ? 'center' : undefined} mb={6}>
      <Icon as={ICON_MAPPING[icon]} size={22} color="primary.light" />
      <Typography variant="h2" color="primary.main" mb={0} ml={2}>
        {title}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </Typography>
    </Flex>
    {children}
    {divider && <Divider />}
  </>
);

Section.propTypes = propTypes;
Section.defaultProps = defaultProps;

export default Section;

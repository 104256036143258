import styled from '@emotion/styled';
import css from '@styled-system/css';

const Grid = styled.div(
  css({
    display: 'grid',
    gridGap: 4,
    gridTemplateColumns: [
      '1fr',
      'repeat(auto-fill, minmax(300px, 1fr))',
      null,
      'repeat(auto-fill, minmax(240px, 1fr))',
      '1fr 1fr',
    ],
  }),
);

export default Grid;

import React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import Card from '@bit/medicalwebexperts.mwe-ui.card';
import Image from '@bit/medicalwebexperts.mwe-ui.image';
import SEO from '../components/Seo/Seo';
import Application from '../components/Application/Application';
import logoImg from '../images/logo.png';
import logoImg2x from '../images/logo@2x.png';

const LogoWrapper = styled.div(
  css({
    mb: 10,
  }),
);

const Main = styled(Card)(
  css({
    px: [2, null, null, 6],
    position: 'relative',
    zIndex: 'docked',
    boxShadow: '0 0 26px rgba(0,0,0,.1)',
    borderRadius: '20px',
  }),
);

const TextGroup = styled.div(
  css({
    mb: 8,
  }),
);

const IndexPage = () => (
  <>
    <SEO title="American Samoa Health Declaration" />
    <Flex flexDirection={['column', null, null, 'row']}>
      <Flex.Box width={['100%', null, null, '40%']} pr={[0, null, null, 10]}>
        <LogoWrapper>
          <Image
            src={logoImg}
            srcSet={`${logoImg} 1x, ${logoImg2x} 2x`}
            alt="American Samoa Departament of Health"
            responsive
          />
        </LogoWrapper>
        <Typography variant="h1" mb={5}>
          Traveler Health Declaration
          <br />
          <strong>American Samoa</strong>
        </Typography>
        <TextGroup>
          <Typography paragraph>
            All persons entering American Samoa must complete this form before arrival.
          </Typography>
        </TextGroup>

      </Flex.Box>
      <Flex.Box width={['100%', null, null, '100%']}>
        <Main>
          <Card.Body>
            <Application />
          </Card.Body>
        </Main>
      </Flex.Box>
    </Flex>
  </>
);

export default IndexPage;

import { object, string, date, array } from 'yup';

export const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  country: '',
  dateOfBirth: '',
  gender: '',
  dateOfArrival: '',
  carrier: '',
  flightVesselNumber: '',
  countriesTraveledConfirm: '',
  countriesTraveled: [],
  countryOfUsualResidence: '',
  villageOrHotelName: '',
  symptoms: {
    feverChills: '',
    cough: '',
    difficultyBreathing: '',
    rash: '',
  },
  testedPositive: '',
  testedPositiveDetails: '',
  otherMedicalConditions: '',
  vaccinatedForCovid: '',
  familyMembers: [],
  ackTerms: '',
};

export const parseValues = ({
  gender,
  country,
  countryOfUsualResidence,
  countriesTraveledConfirm,
  countriesTraveled,
  vaccinatedForCovid,
  testedPositive,
  testedPositiveDetails,
  ...values
}) => ({
  ...values,
  gender: gender.value,
  country: country.value,
  countryOfUsualResidence: countryOfUsualResidence.value,
  countriesTraveledConfirm: countriesTraveledConfirm.value === 'Y' ? 1 : 0,
  countriesTraveled: countriesTraveled.map((country) => country.value),
  vaccinatedForCovid: vaccinatedForCovid.value === 'Y' ? 1 : 0,
  testedPositive: testedPositive.value === 'Y' ? 1 : 0,
  testedPositiveDetails: testedPositiveDetails || undefined,
});

const baseValidations = {
  firstName: string().required().trim().label('First name'),
  lastName: string().required().trim().label('Last name'),
  dateOfBirth: date().required().typeError("A Valid Date of birth is a required").default(undefined).label('Date of birth'),
  gender: object({
    value: string().required().label('Gender'),
  }),
  country: object({
    value: string().required().label('Country'),
  }),
  dateOfArrival: date().required().typeError("A Valid Date of arrival is a required").default(undefined).label('Date of arrival'),
  carrier: string().required().trim().label('Carrier'),
  flightVesselNumber: string().required().trim().label('Flight/Vessel number'),
  countryOfUsualResidence: object({ value: string().required().label('Country of usual residence'), }),
  villageOrHotelName: string().required().trim().label('Village in American Samoa or Hotel Name'),
  countriesTraveledConfirm: object({
    value: string().required().label('Have you traveled to another country in the past 14 days?'),
  }),
  countriesTraveled: array().when(
    'countriesTraveledConfirm', {
    is: 'Y',
    then: array().min(1).required().label('List all the countries you have traveled in the past 14 days'),
  }),
  vaccinatedForCovid: object({
    value: string().required().label('Have you been vaccinated for COVID-19?'),
  }),
  testedPositive: object({
    value: string().required().label('Have you been told in the past month that you tested positive for an infectious disease?'),
  }),
  testedPositiveDetails: object().when(
    'testedPositive',
    (testedPositive, schema) =>
      testedPositive && testedPositive.value === 'Y'
        ? string().required().label('Tested Positive Details')
        : schema,
  ),
  symptoms: object({
    feverChills: string().required().label('Fever, felt feverish or had chills?'),
    cough: string().required().label('Cough'),
    difficultyBreathing: string().required().label('Difficulty breathing'),
    rash: string().required().label('Rash'),
  }).required(),
};

export const validations = object({
  ...baseValidations,
  email: string().required().email().trim().label('Email address'),
  phone: string().required().trim().label('Phone number'),
  familyMembers: array().of(object(baseValidations)).max(10),
});

import React, { useState, useEffect } from 'react';
import { number, string } from 'prop-types';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';

const propTypes = {
  limit: number,
  value: string.isRequired,
};

const defaultProps = {
  limit: 150,
};

const Counter = ({ limit, value }) => {
  const [counter, setCounter] = useState(limit);

  useEffect(() => {
    setCounter(Math.max(0, limit - value.length));
  }, [limit, value]);

  return (
    <Typography variant="caption" color="gray.700">
      Limit: {counter} characters
    </Typography>
  );
};

Counter.propTypes = propTypes;
Counter.defaultProps = defaultProps;

export default Counter;

import React from 'react';
import { string, arrayOf, shape, bool } from 'prop-types';
import { FastField, Field as SlowField } from 'formik';
import Form from '@bit/medicalwebexperts.mwe-ui.form';
import Select from './Select';
import Checkbox from './Checkbox';
import Datepicker from './Datepicker';

const propTypes = {
  label: string,
  name: string.isRequired,
  options: arrayOf(shape({})),
  slow: bool,
  type: string,
};

const defaultProps = {
  label: undefined,
  options: [],
  slow: false,
  type: undefined,
};

const Field = ({ label, name, options, type, slow, ...props }) => {
  const FormikField = slow ? SlowField : FastField;

  return (
    <FormikField name={name}>
      {({ field, form, meta }) => {
        const feedback = meta.error && meta.touched ? meta.error : undefined;
        const isValid = !(meta.error && meta.touched);

        const handleSelectChange = (o) => {
          form.setFieldValue(name, o);
        };

        const handleBlur = () => {
          form.setFieldTouched(name);
        };

        switch (type) {
          case 'select':
            return (
              <Select
                {...field}
                label={label}
                feedback={feedback}
                options={options}
                onChange={handleSelectChange}
                onBlur={handleBlur}
                size="lg"
                {...props}
              />
            );
          case 'checkbox':
          case 'radio':
            return (
              <Checkbox
                feedback={feedback}
                label={label}
                type={type}
                {...field}
                {...props}
              />
            );
          case 'date':
            return (
              <Datepicker
                feedback={feedback}
                label={label}
                size="lg"
                {...field}
                {...props}
              />
            );
          default:
            return (
              <Form.Field
                isValid={isValid}
                feedback={feedback}
                label={label}
                type={type}
                size="lg"
                {...field}
                {...props}
              />
            );
        }
      }}
    </FormikField>
  );
};

Field.propTypes = propTypes;
Field.defaultProps = defaultProps;

export default Field;

import { useEffect } from "react";
import { useFormikContext } from "formik";

const ScrollToError = () => {
  const formik = useFormikContext();
  const submitting = formik?.isSubmitting;

  useEffect(() => {
    const el = document.body.querySelector("div[type='invalid']");
    if (el) {
      const errorEl = el.parentElement ?? el;
      errorEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
      errorEl.focus({ preventScroll: true });
    }
  }, [submitting]);

  return null;
}

export default ScrollToError
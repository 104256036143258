/* eslint-disable react/prop-types */
import React from 'react';
import Form from '@bit/medicalwebexperts.mwe-ui.form';

const Checkbox = ({ label, feedback, type, ...props }) => (
  <Form.Group mb={0}>
    <Form.Check label={label} type={type} {...props} />
    {feedback && <Form.Feedback type="invalid">{feedback}</Form.Feedback>}
  </Form.Group>
);

export default Checkbox;

import React from 'react';
import { string } from 'prop-types';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import { MEDICAL_CONDITIONS_OPTIONS } from '../../constants/application';
import Field from '../Form/Field';

const propTypes = {
  prefix: string,
};

const defaultProps = {
  prefix: undefined,
};

const List = styled.div(
  css({
    mb: 8,
  }),
);

const Item = styled(Flex)(
  css({
    py: [1, null, null, 2],
    px: 2,
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px',
    borderColor: 'gray.100',
    '&:hover': {
      bg: 'gray.50',
    },
  }),
);

const MedicalConditions = ({ prefix }) => (
  <List>
    {MEDICAL_CONDITIONS_OPTIONS.map((condition) => {
      const name = `${prefix || ''}symptoms.${condition.key}`;

      return (
        <Item
          key={condition.key}
          display="grid"
          gridTemplateColumns={['1fr', '0.4fr 0.6fr']}
          gridGap={6}
        >
          <Typography variant="body2">{condition.label}</Typography>
          <Flex.Box>
            <Flex
              display="grid"
              gridTemplateColumns={['1fr', '1fr 1fr']}
              gridGap={4}
            >
              <Flex.Box>
                <Field label="Yes" type="radio" name={name} value="y" />
              </Flex.Box>
              <Flex.Box>
                <Field label="No" type="radio" name={name} value="n" />
              </Flex.Box>
            </Flex>
          </Flex.Box>
        </Item>
      );
    })}
  </List>
);

MedicalConditions.propTypes = propTypes;
MedicalConditions.defaultProps = defaultProps;

export default MedicalConditions;

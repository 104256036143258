/* eslint-disable react/prop-types */
import React from 'react';
import Form from '@bit/medicalwebexperts.mwe-ui.form';

const Select = ({ label, feedback, ...props }) => (
  <Form.Group>
    {label && <Form.Label>{label}</Form.Label>}
    <Form.Select {...props} />
    {feedback && <Form.Feedback type="invalid">{feedback.value || feedback}</Form.Feedback>}
  </Form.Group>
);

export default Select;

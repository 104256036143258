/* eslint-disable react/no-array-index-key */
import React from 'react';
import { arrayOf, shape } from 'prop-types';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { FieldArray } from 'formik';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import Card from '@bit/medicalwebexperts.mwe-ui.card';
import Button from '@bit/medicalwebexperts.mwe-ui.button';
import CloseButton from '@bit/medicalwebexperts.mwe-ui.close-button';
import {
  GENDER_OPTIONS,
  YES_NO_OPTIONS,
} from '../../constants/application';
import Field from '../Form/Field';
import Section from './Section';
import Grid from './Grid';
import Counter from './Counter';
import MedicalConditions from './MedicalConditions';
import { initialValues as baseInitialValues } from './values';

const propTypes = {
  members: arrayOf(shape({})).isRequired,
  countries: arrayOf(shape({})).isRequired,
};

const CloseWrapper = styled.div(
  css({
    position: 'absolute',
    right: 2,
    top: 2,
  }),
);

const initialValues = {
  ...baseInitialValues,
  email: undefined,
  phone: undefined,
  familyMembers: undefined,
  ackTerms: undefined,
};

const FamilyMembers = ({ members, countries }) => {
  return (
    <FieldArray name="familyMembers">
      {({ remove, push }) => (
        <>
          {members.length > 0 &&
            members.map((member, index) => (
              <Card boxShadow="none" bg="gray.50" mb={4} key={index}>
                <Card.Body position="relative">
                  <Typography variant="h2">
                    Family Member {index + 1}
                  </Typography>
                  <CloseWrapper>
                    <CloseButton
                      variantColor="danger"
                      variant="ghost"
                      onClick={() => remove(index)}
                    />
                  </CloseWrapper>
                  <Section icon="person" title="Personal Information">
                    <Grid>
                      <Field
                        label="First name"
                        type="text"
                        name={`familyMembers.${index}.firstName`}
                        helperText="Please enter your name as it appears on your travel documents."
                        autoFocus
                      />
                      <Field
                        label="Last name"
                        type="text"
                        name={`familyMembers.${index}.lastName`}
                      />
                      <Field
                        label="Date of birth"
                        type="date"
                        name={`familyMembers.${index}.dateOfBirth`}
                        placeholder="MM/DD/YYYY"
                      />
                      <Field
                        label="Gender"
                        type="select"
                        name={`familyMembers.${index}.gender`}
                        options={GENDER_OPTIONS}
                      />
                    </Grid>
                    <Typography mt={2} paragraph>
                      Where are you currently located?
                    </Typography>
                    <Grid>
                      <Field
                        label="Country"
                        type="select"
                        name={`familyMembers.${index}.country`}
                        options={countries}
                        slow
                      />
                    </Grid>
                  </Section>
                  <Section icon="pin" title="Travel Information">
                    <Grid>
                      <Field
                        label="Date of arrival"
                        type="date"
                        name={`familyMembers.${index}.dateOfArrival`}
                        placeholder="MM/DD/YYYY"
                      />
                      <Field label="Carrier" type="text" name={`familyMembers.${index}.carrier`} />
                      <Field label="Flight/Vessel number" type="text" name={`familyMembers.${index}.flightVesselNumber`} />

                      <Field
                        name={`familyMembers.${index}.countryOfUsualResidence`}
                        label="Country of usual residence"
                        type="select"
                        options={countries}
                        slow
                      />
                    </Grid>
                    <Field
                      label="Village in American Samoa or Hotel Name"
                      type="text"
                      name={`familyMembers.${index}.villageOrHotelName`}
                    />
                    <Field
                      name={`familyMembers.${index}.countriesTraveledConfirm`}
                      label="Have you traveled to another country in the past 14 days?"
                      type="select"
                      options={YES_NO_OPTIONS}
                    />
                    {member.countriesTraveledConfirm.value === 'Y' && (
                      <Field
                        name={`familyMembers.${index}.countriesTraveled`}
                        label="List all the countries you have traveled in the past 14 days"
                        type="select"
                        options={countries}
                        isMulti
                        slow
                      />
                    )}
                  </Section>
                  <Section
                    icon="favorite"
                    title="Medical Conditions"
                    divider={false}
                  >
                    <Field
                      name={`familyMembers.${index}.vaccinatedForCovid`}
                      label="Have you been vaccinated for COVID-19? (Yes/No)"
                      type="select"
                      options={YES_NO_OPTIONS}
                    />
                    <Typography mb={4}>
                      Are you experiencing any of the following symptoms?
                    </Typography>
                    <MedicalConditions prefix={`familyMembers.${index}.`} />

                    <Field
                      name={`familyMembers.${index}.testedPositive`}
                      label="Have you been told in the past month that you tested positive for an infectious disease?"
                      type="select"
                      options={YES_NO_OPTIONS}
                    />
                    {member.testedPositive.value === 'Y' && (
                      <Field
                        as="textarea"
                        label="Please specify"
                        name={`familyMembers.${index}.testedPositiveDetails`}
                        maxLength="150"
                        minHeight="120px"
                      />
                    )}
                    <Field
                      as="textarea"
                      label="Other medical conditions (Optional)"
                      name={`familyMembers.${index}.otherMedicalConditions`}
                      maxLength="150"
                      minHeight="120px"
                      helperText={
                        <Counter
                          value={member.otherMedicalConditions}
                        />
                      }
                    />

                  </Section>
                </Card.Body>
              </Card>
            ))}
          {members.length < 10 && (
            <Button
              variant="outlined"
              variantColor="primary"
              size="lg"
              width="auto"
              onClick={() => push(initialValues)}
              block
            >
              Add Family Member
            </Button>
          )}
        </>
      )}
    </FieldArray>
  );
};

FamilyMembers.propTypes = propTypes;

export default FamilyMembers;

export const ID_TYPE_OPTIONS = [
  { value: 'PP', label: 'Passport Number' },
  { value: 'CI', label: 'Certificate of Identity Number' },
];

export const GENDER_OPTIONS = [
  { value: 'M', label: 'Male' },
  { value: 'F', label: 'Female' },
  { value: 'R', label: 'Refuse to answer' },
];

export const YES_NO_OPTIONS = [
  { value: 'Y', label: 'Yes' },
  { value: 'N', label: 'No' },
];

export const MEDICAL_CONDITIONS_OPTIONS = [
  { key: 'feverChills', label: 'Fever, felt feverish or had chills' },
  { key: 'cough', label: 'Cough' },
  { key: 'difficultyBreathing', label: 'Difficulty breathing' },
  { key: 'rash', label: 'Rash' },
];

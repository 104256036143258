/* eslint-disable react/prop-types */
import React from 'react';
import InputMask from 'react-input-mask';
import Form from '@bit/medicalwebexperts.mwe-ui.form';

const Datepicker = ({ label, feedback, size, ...props }) => (
  <Form.Group>
    {label && <Form.Label>{label}</Form.Label>}
    <InputMask mask="99/99/9999" {...props}>
      <Form.Control type="text" size={size} />
    </InputMask>
    {feedback && <Form.Feedback type="invalid">{feedback}</Form.Feedback>}
  </Form.Group>
);

export default Datepicker;

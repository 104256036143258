import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { navigate } from 'gatsby';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Button from '@bit/medicalwebexperts.mwe-ui.button';
import Form from '@bit/medicalwebexperts.mwe-ui.form';
import Link from '@bit/medicalwebexperts.mwe-ui.link';
import {
  GENDER_OPTIONS,
  YES_NO_OPTIONS,
} from '../../constants/application';
import client from '../../services/client';
import Field from '../Form/Field';
import Section from './Section';
import Grid from './Grid';
import Counter from './Counter';
import MedicalConditions from './MedicalConditions';
import FamilyMembers from './FamilyMembers';
import ScrollToError from '../Form/ScrollToError';
import { initialValues, parseValues, validations } from './values';

const ackTermsText =
  'I hereby acknowledge that I have read, understand, and agree to the terms of this application';

const Application = () => {
  const [countries, setCountries] = useState([]);
  const [buttonAvailable, setButtonAvailable] = useState();

  const onSubmit = async ({ familyMembers, ...values }) => {
    try {
      await client.service('traveler-health-declarations').create({
        ...parseValues(values),
        familyMembers: familyMembers.map(parseValues),
        ackTermsText,
      });

      navigate('/thank-you', { replace: true });
    } catch {
      //
    }
  };

  useEffect(() => {
    const load = async () => {
      const response = await client
        .service('countries')
        .find({ paginate: false })
        .catch(() => []);

      setCountries(response.map((c) => ({ value: c.alpha3Code, label: c.name })));
    };

    load();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validations}
      onSubmit={onSubmit}
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <Section icon="person" title="Personal Information">
            <Grid>
              <Field
                label="First name"
                type="text"
                name="firstName"
                helperText="Please enter your name as it appears on your travel documents."
              />
              <Field label="Last name" type="text" name="lastName" />
              <Field label="Email address" type="email" name="email" />
              <Field
                label="Phone number"
                type="tel"
                name="phone"
              />
              <Field
                label="Date of birth"
                type="date"
                name="dateOfBirth"
                placeholder="MM/DD/YYYY"
              />
              <Field
                label="Gender"
                type="select"
                name="gender"
                options={GENDER_OPTIONS}
              />
              <Field
                label="Country"
                type="select"
                name="country"
                options={countries}
                slow
              />
            </Grid>

          </Section>
          <Section icon="pin" title="Travel Information">
            <Grid>
              <Field
                label="Date of arrival"
                type="date"
                name="dateOfArrival"
                placeholder="MM/DD/YYYY"
              />
              <Field label="Carrier" type="text" name="carrier" />
              <Field label="Flight/Vessel number" type="text" name="flightVesselNumber" />

              <Field
                name="countryOfUsualResidence"
                label="Country of usual residence"
                type="select"
                options={countries}
                slow
              />
            </Grid>
            <Field label="Village in American Samoa or Hotel Name" type="text" name="villageOrHotelName" />
            <Field
              name="countriesTraveledConfirm"
              label="Have you traveled to another country in the past 14 days?"
              type="select"
              options={YES_NO_OPTIONS}
            />
            {values.countriesTraveledConfirm.value === 'Y' && (
              <Field
                name="countriesTraveled"
                label="List all the countries you have traveled in the past 14 days"
                type="select"
                isMulti
                options={countries}
                slow
              />
            )}
          </Section>
          <Section icon="favorite" title="Medical Conditions">
            <Field
              name="vaccinatedForCovid"
              label="Have you been vaccinated for COVID-19?"
              type="select"
              options={YES_NO_OPTIONS}
            />
            <Typography paragraph>
              Are you experiencing any of the following symptoms?
            </Typography>
            <MedicalConditions />
            <Field
              name="testedPositive"
              label="Have you been told in the past month that you tested positive for an infectious disease?"
              type="select"
              options={YES_NO_OPTIONS}
            />
            {values.testedPositive.value === 'Y' && (
              <Field
                as="textarea"
                label="Please specify"
                name="testedPositiveDetails"
                maxLength="150"
                minHeight="120px"
              />
            )}
            <Field
              as="textarea"
              label="Other medical conditions (Optional)"
              name="otherMedicalConditions"
              maxLength="150"
              minHeight="120px"
              helperText={
                <Counter
                  value={values.otherMedicalConditions}
                />
              }
            />

          </Section>
          <Section
            icon="account"
            title="Family Members Traveling With You"
            subtitle={`If any family members will be traveling to American Samoa with you,
            please enter their information below.  If you’re traveling alone, do not enter
            any family members. Any family members that you’ve listed on this form do not
            need to submit separate applications. By listing them in your application,
            you are applying on their behalf.`}
          >
            <FamilyMembers members={values.familyMembers} countries={countries} />
          </Section>
          <Flex alignItems="center">
            <Field label="" type="checkbox" name="ackTerms" onChange={(e) => setButtonAvailable(e.target.checked)} />
            <Typography variant="body2">
              I hereby acknowledge that I have read, understand, and agree to
              the{' '}
              <Link href="/terms/" color="primary.light" target="_blank">
                terms of this application
              </Link>
            </Typography>
          </Flex>
          <Button
            type="submit"
            variantColor="success"
            loading={isSubmitting}
            size="lg"
            mt={8}
            block
            disabled={!buttonAvailable}
          >
            Submit Travel Health Declaration
          </Button>
          <ScrollToError />
        </Form>
      )}
    </Formik>
  );
};

export default Application;
